.Project{
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 60%;
    row-gap: 25px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: start;
}
.Project hr{
    width: 100%;
}
.Project-Title{
    text-align: start;
    color: #f0f0f0;
    font-size: 26px;
}
.Project-Title-2{
    color: #f0f0f0;
    font-size: 20px;
}
.Project-Title-About{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Project-Paragraph{
    text-align: justify;
    font-size: 18px;
    color: #cccccc;
}
.Project-Overview{
    width: 100%;
    min-height: 500px;
    max-height: 750px;
}
.Project-Overview img{
    width: 100%;
    height: 100%;
}
.Project-Tech{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 25px;
}
.Project-Tech div{
    width: 125px;
    height: 125px;
    background-color: #f0f0f0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Project-ImageContainer{
    width: 125px;
    height: 125px;
    background-color: #f0f0f0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Project-Studies{
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    column-gap: 25px;
    row-gap: 25px;
}
.Project-Tech div img, .Project-ImageContainer img{
    margin: 10px;
    width: 100%;
    height: 50px;
    object-fit: contain;
}
.Project-Tech div p, .Project-Studies div p{
    font-size: 12px;
    color: rgb(90, 90, 90);
}
.Project-GymImages{
    width: 100%;
    /* min-height: 500px;
    max-height: 750px; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 25px;
}
.Home-Project-Game{
    min-height: 350px;
    max-height: 500px;
}
.Project-GymImages img{
    /* width: 100%; */
    min-height: 350px;
    max-height: 500px;
    /* width: 100%;
    height: 100%; */
}
.Project-Avatar img{
    min-height: 150px;
    max-height: 500px;
}
.Project-Management{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.Project-Management img{
    min-height: 150px;
    max-height: 400px;
}
.Project-Sport{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 25px;
}
.Project-Sport img{
    min-height: 350px;
    max-height: 500px;
}
@media screen and (max-width: 640px) {
    .Project{
        width: 80%;
    }
  }
  @media (min-width: 640px) and (max-width: 1000px) {
    .Project{
        width: 70%;
    }
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    .Project{
        width: 60%;
    }
  }
  @media (min-width: 1400px) and (max-width: 1920px) {
    .Project{
        width: 60%;
    }
  }