.Games-Projects{
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}
.Games-Projects hr{
    border-bottom: 1px solid #f0f0f0;
}
.Games-Projects-List{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 50px;
}
.Games-Projects-Project{
    display: flex;
    flex-direction: column;
    align-items:start;
    justify-content: space-around;
    width: 450px;
    height: 250px;
    background-color:#14113a;
    border: 2px solid #342f92;
    padding:25px;
    h3{
        text-align:start;
        margin: 0;
        color: #f0f0f0;
        font-size: 16px;
        height: 100px;
    }
    p{
        text-align: start;
        font-size: 12px;
        color: #f0f0f0;
        height: 100px;
    }
    a{
        transition: all 0.2s ease-in;   
    }
    a:hover{
    color: #f0f0f0;
    font-size: larger;
    }
}

.Games-Projects-Project img{
    max-height: 50px;
    max-width: 50px;
}
@media screen and (max-width: 640px) {
    .Games-Projects-Project{
        width: 100%;
    }
  }
  @media (min-width: 640px) and (max-width: 1100px) {
    .Games-Projects-Project{
        width: 100%;
    }
  }
  @media (min-width: 1100px) and (max-width: 1400px) {
    .Games-Projects-Project{
        width: 450px;
    }
  }
  @media (min-width: 1400px) and (max-width: 1920px) {
    .Games-Projects-Project{
        width: 450px;
    }
  }