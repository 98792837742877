.Home-Projects-ProjectHeader{
    text-align: start;
    font-weight: bolder;
    font-size: 20px;
}
.Home-Projects-ProjectParagraph{
    text-align: start;
    font-size: 16px;
}
.Home-Projects-Container{
    color: #f0f0f0;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 30px;
    margin: 0 10px;
}
.Home-Projects-Container img{
    width: 75px;
    height: 75px;
}
.Home-Projects-Project{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
    background-color:#14113a;
    border: 2px solid #342f92;
    border-radius: 5px;
    max-height: 250px;
    overflow: hidden;
}

.Home-Projects-Project div{
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    max-height: 225px;
}
.Home-Projects-Project div a{
    margin-left: 10px;
    color: #4943c9;
    transition: all 0.2s ease-in-out;   
}
.Home-Projects-Project div a:hover{
    color: #f0f0f0;
    font-size: larger;
}

.Home-Projects-More{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
}
.Home-Projects-More a{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    column-gap: 25px;
    transition: all 0.2s ease-in-out;
}
.Home-Projects-More img{
    width: 50px;
    height: 50px;
    transition: all 0.2s ease-in-out;
}
.Home-Projects-More a:hover{
    transform: scale(1.1);
}
@media screen and (max-width: 640px) {
    .Home-Projects-Container{
        grid-template-columns: auto;
    }
    .Home-Projects-Project{
        max-height: fit-content;
        flex-direction: column;
    }
    .Home-Projects-Project div{
        max-height: fit-content;
    }
    .Home-Projects-ProjectHeader{
        text-align: center;
    }
  }
  @media (min-width: 640px) and (max-width: 1000px) {
    .Home-Projects-Container{
        grid-template-columns: auto;
    }
    .Home-Projects-Project{
        max-height: fit-content;
    }
    .Home-Projects-Project div{
        max-height: fit-content;
    }
  }
  @media (min-width: 1000px) and (max-width: 1400px) {
    .Home-Projects-Container{
        grid-template-columns: auto;
    }
  }
  @media (min-width: 1400px) and (max-width: 1920px) {
    .Home-Projects-Container{
    grid-template-columns: auto auto;
    }
  }
/* .Home-Project-Container{
    
} */