@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../../fonts/Ubuntu-Regular.ttf') format('truetype');
}

* {
    font-family: 'Ubuntu';
}

body {
    font-family: 'Ubuntu', sans-serif;
}

:root {
    --card-height: 20rem;
    --card-width: calc(var(--card-height) / 1.5);
}

.card {
    background: #191c29;
    width: var(--card-width);
    height: var(--card-height);
    padding: 3px;
    position: relative;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 0.5rem;
    color: rgb(88 199 250 / 0%);
    cursor: pointer;
    transition: all 0.4s;
    z-index: 1;
}

.card1 {
    background: #191d31;
    min-width: var(--card-width);
    min-height: var(--card-height);
    /* padding: 3px; */
    position: relative;
    border-radius: 0.5rem;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;
    text-align: center;
    display: flex;
    font-size: 1rem;
    color: rgb(88 199 250 / 0%);
    cursor: pointer;
    /* font-family: cursive; */
    z-index: 1;

}

.card:hover {
    color: rgb(88 199 250 / 100%);
    transform: scale(1.1);
    transform-origin: center;
    transition: all 0.4s ease-3n;
    animation: spin 2.5s linear infinite;
}


/* .card:hover .picture {
    color: rgb(88 199 250 / 100%);
    transform: scale(1.3);
    transform-origin: bottom;
    transition: all 0.5s ease-3n;
    border-radius: 1rem;
    animation: spin 2.5s linear infinite;
} */

/* .card:hover:after picture,
.card:hover:before picture {
    animation: none;
    transform: scale(1);
    transform-origin: center;
    transition: all 0.3s;
    animation: spin 2.5s linear infinite;
} */

.card:hover:before{
    animation: spin 2.5s linear infinite;
    
}
.card:hover:after {
    /* opacity: 0; */
    transform: scale(1.01);
    transform-origin: center;
    transition: all 0.4s;
    filter: blur(calc(var(--card-height) / 50));
    animation: spin 2.5s linear infinite;
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2); 

}


.card::before {
    content: "";
    padding: 1rem;
    width: 104%;
    height: 102%;
    z-index: -1;
    border-radius: 0.5rem;
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
    position: absolute;
    top: -1%;
    left: -2%;
    opacity: 1;
    transition: opacity 0.4s;

}

.card::after {
    position: absolute;
    content: "";
    /* top: calc(var(--card-height) / 6); */
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.55);
    /* filter: blur(calc(var(--card-height) / 6));
    background-image: linear-gradient(var(--rotate), #5ddcff, #3c67e3 43%, #4e00c2);
    opacity: 1;
    transition: all 0.3s;
    animation: spin 2.5s linear infinite; */
}

.picture {
    /* padding: 5rem; */
    width: 100%;
    height: 100%;
    z-index: -1;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    transition: a3l;
}

.description {
    color: rgb(28, 192, 184);
    overflow-wrap: break-word;
    inline-size: 100%;
    padding: 0.5rem;
}

.title {
    padding-top: 0.5rem;
    padding-left: 1rem;
    font-size: 1.3rem;
    align-self: flex-start;
    color: rgb(28, 192, 184);
}

.play {
    color: rgb(28, 192, 184);
    position: absolute;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    bottom: 0.1rem;
    font-weight: 700;
    font-size: 1.5rem;
    margin: 10px;
    border: 2px solid rgb(13, 190, 221);
    border-radius: 0.5rem;
    background-color: rgb(86, 28, 194);
}

@media only screen and (max-width: 768px) {
    .card::before{
        animation: none;
    }
}



@keyframes spin {
    0% {
        --rotate: 0deg;
    }

    100% {
        --rotate: 360deg;
    }
}