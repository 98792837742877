@media (min-width: 1600px) {
  .grid-main {
    grid-template-columns: auto auto auto;
  }
}
@media (max-width: 1600px) {
  .grid-main {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 1100px) {
  .grid-main {
    grid-template-columns: auto;
  }
}

.grid-title {
  font-size: 30px;
  text-align: center;
  color: #f0f0f0;
}
.grid-main {
  width: 100%;
  display: grid;
  align-items: flex-start;
  align-content: center;
  .grid-cell {
    margin: 20px auto;
  }
}

.grid-main-no-margin {
  left: 50%;
  width:auto;
  transform: translateX(-50%);
  position: relative;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  .grid-cell {
    position: relative;
    margin: 0;
  }
}
