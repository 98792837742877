$haku-ani-duration: 0.8s;

@media (min-width:640px){
  .haku-mouse-trail{
    display: inline;
  }
}
@media(max-width:640px){
  .haku-mouse-trail{
    display: none;
  }
}

body {
  letter-spacing: normal;
}
* {
  letter-spacing: normal;
}
.site-content {
  margin-top: 00px;
}

.title {
  text-align: left;
}

.text {
  text-align: left;
}

.mainContainer {
  @media (max-width: 640px) {
    width: 90%;
  }

  width: 80%;
  margin: auto;
  margin-top: 100px;
  .mainContainer-title {
    position: relative;
    font-size: 30px;
    color: #1ac0b8;
    margin-top: -30px;
    margin-left: 200px;
    font-weight: bold;
    z-index: 1;
  }
}

.homeContactContainer {
  align-items: center;
  align-content: center;
  .home-contact-title {
    font-size: 40px;
    text-align: center;
  }
  .home-contact-textarea {
    position: relative;
    margin-top: 50px;
    left: 50%;
    height: 300px;
    width: 60%;
    transform: translateX(-50%);
    font-size: 20px;
    &:focus {
      border: 0;
      outline: none;
    }
  }
}

.contactContainer {
  @media (min-width: 900px) {
    .contact-data-box {
      flex-direction: row;
      .home-contact {
        max-height: "fitContent";
        width: 50%;
        margin: "auto";
        background-color: "red";
      }
    }
    .contact-up-line {
      width: 1px;
      background-color: white;
      // height: 100%;
      top: 0%;
      bottom: 0%;
      position: relative;
      margin: -30px 50px;
    }
    .contact-messege-box {
      .contact-data-box-textArea {
        height: 60%;
      }
    }
  }
  @media (max-width: 900px) {
    .contact-data-box {
      flex-direction: column;
      .home-contact {
        width: 100%;
      }
    }
    .contact-messege-box {
      .contact-data-box-textArea {
        height: 400px;
      }
    }
    .contact-data-box-send {
      button {
        margin: 20px;
      }
    }
  }
  .contact-data-box {
    display: flex;
    font-size: 20px;
    .home-contact {
      max-height: "fitContent";
      margin: "auto";
      background-color: "red";
    }
  }

  .contact-data-bos-text {
    text-align: justify;
    flex: 1;
    color: #f0f0f0;
  }
  .contact-messege-box {
    min-height: 400px;
    border: 1px solid white;
    border-radius: 0.5rem;
    flex: 1;
    .contact-messege-box-title {
      font-size: 30px;
      text-align: center;
    }
    .contact-data-box-textArea {
      width: 80%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      padding: 10px;
      border-radius: 0.5rem;
    }
    .contact-data-box-send {
      button {
        border-radius: 0.5rem;
        position: relative;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 40px;
        background-color: #1ac0b8;
        position: relative;
        color: black;
        &:focus {
          border: 0;
          outline: none;
        }
      }
    }
  }
}

.full-width {
  position: relative;
  width: 125%;
  left: -12.5%;
}

.normal-width {
  width: 80%;
  margin: 0 auto;
}

.contact-map {
  min-height: 90vh;
}

.haku-p {
  position: relative;
  left: 50%;
  font-size: 100px;
  transform: scale(1);
  text-align: center;
}

.haku-from-bottom {
  opacity: 0;
  transform: translateY(30%);
  transition: all $haku-ani-duration;
}
.haku-from-top {
  opacity: 0;
  transform: translateY(-30%);
  transition: all $haku-ani-duration;
}
.haku-from-left {
  opacity: 0;
  transform: translateX(-30%);
  transition: all $haku-ani-duration;
}
.haku-from-right {
  opacity: 0;
  transform: translateX(30%);
  transition: all $haku-ani-duration;
}
.haku-from-scale {
  transform: scale(10%);
  transition: all $haku-ani-duration;
}
.haku-from-active {
  opacity: 1;
  transform: translateY(0%) translateX(0%) scale(100%);
}

.haku-test-text {
  color: red;
}

.haku-from-hide-ani {
  position: relative;
  overflow: hidden;
  .haku-from-hide-ani-text {
    opacity: 0;
    padding: 20px;
  }
  .haku-from-hide-ani-mask {
    position: absolute;
    background-color: #273e7a;
    width: 100%;
    height: 100%;
    top: 0%;
    opacity: 0;
    z-index: -1;
  }
}
.haku-from-hide-ani-active {
  $aniDuration: $haku-ani-duration;
  .haku-from-hide-ani-mask {
    @keyframes ani {
      0% {
        z-index: inherit;
        transform: translateX(-100%);
        opacity: 0;
      }
      1% {
        opacity: 1;
      }
      50% {
        transform: translateX(0%);
      }
      99% {
        opacity: 1;
      }
      100% {
        z-index: inherit;
        transform: translateX(100%);
        opacity: 0;
      }
    }
    animation: ani $aniDuration;
  }
  .haku-from-hide-ani-text {
    opacity: 1;
  }
  .haku-from-hide-ani-text {
    @keyframes ani2 {
      0% {
        opacity: 0;
      }
      49% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    animation: ani2 $aniDuration;
  }
}

.haku-from-hide-ani-active2 {
  $aniDuration: $haku-ani-duration;
  .haku-from-hide-ani-mask {
    @keyframes ani3 {
      0% {
        z-index: inherit;
        transform: translateX(100%);
        opacity: 0;
      }
      1% {
        opacity: 1;
      }
      50% {
        transform: translateX(0%);
      }
      99% {
        opacity: 1;
      }
      100% {
        z-index: inherit;
        transform: translateX(-100%);
        opacity: 0;
      }
    }
    animation: ani3 $aniDuration;
  }
  .haku-from-hide-ani-text {
    opacity: 1;
  }
  .haku-from-hide-ani-text {
    @keyframes ani2 {
      0% {
        opacity: 0;
      }
      49% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    animation: ani2 $aniDuration;
  }
}


