
.about_Container{
    display: flex;
    flex-direction: column;
    min-height: 75vh;
    position: relative;
    /* background-color: rgba(18, 76, 182, 0.466); */
}

.backgroundImageTop{
    position: absolute;
    width: 100vw;
    height: 150vh;
    background-image: url("../../assets/images/line/Untitled-3-07.png");
    background-size: 500vh;
    background-repeat: repeat;
    background-position: center;
    /* background-position-y: 1rem; */
    background-color: rgba(20, 17, 58, .2);
    opacity: .03;
}

.backgroundImageBottom{
    transform: rotateZ(60deg);
    position: absolute;
    width: 100vw;
    height: 150vh;
    background-image: url("../../assets/images/line/Untitled-3-03.png");
    background-size: 180vh;
    background-repeat: no-repeat;
    background-position: center;
    /* background-position-y: 1rem; */
    background-color: rgba(20, 17, 58, .2);
    opacity: .03;
}
.about_Title{
    font-weight: 900;
    /* font-size: 1.5rem; */
    align-self: center;
    margin-top: 5rem;
    text-align: center;
    white-space: nowrap;
    text-align: center;
    background-color: rgba(20, 17, 58, .2);
    /* border-radius: 0.5rem; */
}

.about_picContainer{
    display: flex;
    width: 100%;
    position: relative;
}

.about_imgMask{
    width: 50%;
    height: 50%;
    display: flex;
    overflow: hidden;
    transform: translateY(-200px);

}
.about_picText1{
    position: relative;
    width: 70%;
    margin-left: 15%;
    align-self: center;
    /* font-size: 1.5rem; */
    height: 100%;
    padding-left: 0.5rem;
    padding-right: .5rem;
    text-align: justify;
    color: #f0f0f0;
}
.about_box{
    padding: 1.5rem;
    text-align: justify;
    color: #f0f0f0;
    
}
.about_boxTitle{
    font-weight: 700;
    padding-bottom: 1rem;
    color: #f0f0f0;
}

.about_boxRight{
    border-right: 1px solid;
}
.about_boxMiddle{
    border-right: 1px solid;
}
.about_textContainer{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* background-color: rgba(20, 17, 58, 1); */
}

.backgroundImageMiddle{
    /* transform: rotateZ(-30deg); */
    position: absolute;
    width: 100vw;
    height: 175vh;
    background-image: url("../../assets/images/line/Untitled-3-03.png");
    background-size: 180vh;
    background-repeat: no-repeat;
    background-position: center;
    /* background-position-y: 1rem; */
    background-color: rgba(20, 17, 58, .2);
    opacity: .03;
}

@media only screen and (max-width: 1200px){
    .about_textContainer{
        flex-direction: column;
    }
    .about_boxRight{
        border: none;
    }
    .about_boxLeft{
        border-top: 1px solid;
    }
    .about_boxMiddle{
        border-top: 1px solid;
        border-right: none;
    }
    .about_picContainer{
        flex-direction: column;
        align-items: center;
    }
    .about_picText{
        width: 90%;
        padding: 0;
    }

    .about_picText1{
        width: 90%;
        padding: 0;
        margin: 2rem;
    }

    .about_mainPicture{
        scale: 2;
    }
    .about_imgMask{
        width: 90%;
        overflow: hidden;
    }
}

