
@media screen and (max-width: 640px) {
    .Home-Aboutt{
        font-size: large;
    }
}
@media (min-width: 640px) and (max-width: 1000px) {
    .Home-Aboutt{
        font-size: 24px;
    }
}
@media (min-width: 1000px) and (max-width: 1400px) {
    .Home-Aboutt{
        font-size: 24px;
    }
}
@media (min-width: 1400px) and (max-width: 1920px) {
    .Home-Aboutt{
        font-size: 24px;
    }
}