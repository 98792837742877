
.logo{
    display: flex;

}
.logo{
    .logoWord{
        opacity: 0;
        transform: translateX(-200px);
        transition: all .4s ease-in-out; 
    }
    .logo8{
        transform: scale(1.3) translateX(-75px);
        transition: all .4s ease-in-out; 
    }
}

.logo:hover{
    .logoWord{
        opacity: 1;
        transform: translateX(0px);
        transition: all 0.4s ease-in; 
    }
    .logo8{
        transform: rotate(180deg);
        transition: all 0.4s ease-in; 
    }
}

.logo::after{
    transition: all 0.5 ease-out;
}