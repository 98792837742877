.Home-Partners{
    text-align: center;
}
.Home-Partners div{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    row-gap: 25px;
    column-gap: 25px;
    flex-wrap: wrap;
}
.Home-Partners img{
    width: 100%;
    height: 100%;
    max-height: 175px;
    max-width: 175px;
    min-height: 25px;
    min-width: 75px;
}
@media screen and (max-width: 640px) {
    .Home-Partners img{
        max-height: 100px;
        max-width: 100px;
        min-height: 25px;
        min-width: 75px;
    }
}
@media (min-width: 640px) and (max-width: 1000px) {
}
@media (min-width: 1000px) and (max-width: 1400px) {
}
@media (min-width: 1400px) and (max-width: 1920px) {
}